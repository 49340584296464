import revive_payload_client_R2HzUN342Z from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti_rbu63l5jxlmy65bsiy3x43nlaq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_567r2c7TOQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti_rbu63l5jxlmy65bsiy3x43nlaq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fTJ9i8LV2F from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti_rbu63l5jxlmy65bsiy3x43nlaq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_s0ylIsK1zW from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_sass-embed_nnfw65chzq3n62mszq7qsxpahi/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_BCfvv1EAkk from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti_rbu63l5jxlmy65bsiy3x43nlaq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_t9tPqTv2TT from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti_rbu63l5jxlmy65bsiy3x43nlaq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Rtb67q2Wiv from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti_rbu63l5jxlmy65bsiy3x43nlaq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_QGylkynDf3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti_rbu63l5jxlmy65bsiy3x43nlaq/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_Lb2IiU6QA7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti_rbu63l5jxlmy65bsiy3x43nlaq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ASGcABqDKm from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_3XqRJc5QKO from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@azure+identity@4.4.1_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti_rbu63l5jxlmy65bsiy3x43nlaq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/webapp/.nuxt/unocss.mjs";
import plugin_k38CJsZ9ad from "/vercel/path0/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.21.3_typescript@5_37qsbc5xhodoqycbryslx42y6m/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import titles_5M4PlmLf6p from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5__pyaflki47gg3tinvylsklblflq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_AOaNikCe4R from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_sass-em_5fq6yvn5pnrazlobs5gdrfibcy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_BX1DEXJyXV from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_sass-em_5fq6yvn5pnrazlobs5gdrfibcy/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import _00_setup_OesUu83x3t from "/vercel/path0/packages/ui/plugins/00.setup.ts";
import defaults_fttFT7HXBz from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5__pyaflki47gg3tinvylsklblflq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_R2HzUN342Z,
  unhead_567r2c7TOQ,
  router_fTJ9i8LV2F,
  _0_siteConfig_s0ylIsK1zW,
  payload_client_BCfvv1EAkk,
  navigation_repaint_client_t9tPqTv2TT,
  check_outdated_build_client_Rtb67q2Wiv,
  view_transitions_client_QGylkynDf3,
  chunk_reload_client_Lb2IiU6QA7,
  plugin_vue3_ASGcABqDKm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3XqRJc5QKO,
  unocss_MzCDxu9LMj,
  plugin_k38CJsZ9ad,
  titles_5M4PlmLf6p,
  siteConfig_AOaNikCe4R,
  inferSeoMetaPlugin_BX1DEXJyXV,
  _00_setup_OesUu83x3t,
  defaults_fttFT7HXBz
]